import { PayloadAction } from "@reduxjs/toolkit";
import { apiCall } from "../../utils/apiCall";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { cargaActions } from "./reducer";
import { formatError } from "../../utils/formatError";


function* cargasWorker() {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/cargas`,
      method: "get",
    });
    yield put(cargaActions.cargasRequestSuccess(res.data));
  } catch (error) {
    yield put(
      cargaActions.cargasError(formatError(error))
    );
  }
}

function* cargaWorker({ payload }: PayloadAction<{ ukey: string }>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/cargas/${payload.ukey}`,
      method: "get",
    });
    yield put(cargaActions.cargaRequestSuccess(res.data));
  } catch (error) {
    yield put(
      cargaActions.cargasError(formatError(error))
    );
  }
}


function* cargaAgrupamentoRequestWorker({ payload }: PayloadAction<{ ukey: string; codigo_agrupamento: string; }>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/cargas/${payload.ukey}/agrupamento/${payload.codigo_agrupamento}`,
      method: "get",
    });
    yield put(cargaActions.cargaAgrupamentoRequestSuccess(res.data));
  } catch (error) {
    yield put(
      cargaActions.cargasError(formatError(error))
    );
  }
}

export function* cargaSaga() {
  yield all([
    takeLatest("carga/cargasRequest", cargasWorker),
    takeLatest("carga/cargaRequest", cargaWorker),
    takeLatest("carga/cargaAgrupamentoRequest", cargaAgrupamentoRequestWorker),
  ]);
}
