import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { FiTruck } from "react-icons/fi"
import { TransbordoItem } from "../types"
import { formatCelular } from "../../../utils/formatCelular"

type BotaoLinkEntregaProps = {
  transbordoItem: TransbordoItem;
}

export const BotaoLinkEntrega = ({ transbordoItem }: BotaoLinkEntregaProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <React.Fragment>
      {transbordoItem?.nota_dados_complementares_entrega_link_app && <Button onClick={onOpen} size="sm" ml={1} colorScheme="orange"><Text mr={1}>LINK ENTREGA</Text> <FiTruck fontSize="1.3rem" /></Button>}

      <Modal size={"2xl"} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link gerado para a entrega <Button onClick={onClose} colorScheme="blackAlpha">Voltar</Button></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={4}>
            <div className="w-full flex flex-col">
              <div>
                Motorista <br />
                <Input readOnly size={"sm"} value={transbordoItem?.nota_dados_complementares_entrega_motorista_agrupamento} />
              </div>
              <div className="mt-1">
                Celular <br />
                <Input readOnly size={"sm"} value={formatCelular(transbordoItem?.nota_dados_complementares_entrega_celular_motorista_agrupamento)} />
              </div>
              <div className="mt-1">
                Link <br />
                <Input readOnly size={"sm"} value={transbordoItem?.nota_dados_complementares_entrega_link_app} />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  )
}