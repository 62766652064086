import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonBack, InputField, Layout } from "../../../components"
import { RootState } from "../../app/mainReducer";
import { AcaoHeader, BotaoFinalizarEntrega, Images, ModalObservacoes, Temporizador } from "../components"
import { useEffect, useState } from 'react';
import { Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { entregaActions } from "../reducer";
import { Carga, CargaItem } from "../../carga/types";
import { buscaCoordenadasAtuais } from "../../../utils/geolocalizacao";

const RegistrarEntrega = () => {
  const gestao_entrega_temporario = useSelector((state: RootState) => state.entrega.gestao_entrega_temporario);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ukey, tipo, j10_ukey } = useParams();
  const e_transbordo = tipo === 'transbordo';
  const carga = useSelector((state: RootState) =>
    (state.carga.carga || state.carga.cargas?.find((i) => i.j14_ukey === ukey))
  );
  const nota = useSelector((state: RootState) => carga?.itens?.find((i: CargaItem) => i.j10_ukey === j10_ukey));

  const [rotaDirecionar, setRotaDirecionar] = useState<string | null>(null);

  useEffect(() => {
    if (nota?.nota_dados_complementares_entrega_link_app) {
      const parsedUrl = new URL(nota?.nota_dados_complementares_entrega_link_app);
      setRotaDirecionar(parsedUrl.pathname);
    } else {
      setRotaDirecionar(null);
    }
  }, [])

  useEffect(() => {
    if (!gestao_entrega_temporario) {
      if (e_transbordo) {
        navigate(`/`)
        return;
      }

      if (rotaDirecionar) {
        navigate(rotaDirecionar);
        return;
      }

      navigate(`/cargas/${ukey}`)
    }
  }, [navigate, gestao_entrega_temporario]);

  const [openModalObservacoes, setOpenModalObservacoes] = useState(false);
  const [openedModalObservacoes, setOpenedModalObservacoes] = useState(false);

  return (
    <Layout showFooter={true} isLoading={false} title={
      <>
        <ButtonBack />
        <Temporizador />
      </>
    }>
      <AcaoHeader label="Registrar Entrega" />

      <Flex px={4} width="full" direction="column">
        {gestao_entrega_temporario && <Formik
          enableReinitialize
          initialValues={gestao_entrega_temporario}
          onSubmit={async (val, { setErrors }) => {
            if (!val.nome_recebedor) {
              setErrors({ nome_recebedor: 'Campo obrigatório' })
              return;
            }
            if (/[0-9]/g.test(val.nome_recebedor)) {
              setErrors({ nome_recebedor: 'Este campo não aceita números!' })
              return;
            }
            if (!val.url_imagem1) {
              setErrors({ url_imagem1: 'Tire a foto do canhoto!' })
              return;
            }

            if (!openedModalObservacoes) {
              setOpenModalObservacoes(true);
              return;
            }

            if (val?.teve_devolucao_mercadoria === null && !e_transbordo && nota?.e_nota_pallet != '1') {
              setErrors({ teve_devolucao_mercadoria: 'Selecione uma opção' })
              return;
            }

            if (val?.teve_devolucao_mercadoria == 1 && !e_transbordo && nota?.e_nota_pallet != '1') {
              if (!val?.observacoes) {
                setErrors({ observacoes: 'Preencha o motivo da devolução.' })
                return;
              }

              if (!val?.url_imagem2) {
                setErrors({ url_imagem2: 'Tire a foto da Nota Fiscal' })
                return;
              }
            }

            if (!e_transbordo && nota?.e_nota_pallet == '1') {
                // nao vai retornar e nao colocou motivo
                if (
                    val.status_nota_pallet_gestao_entrega == 1 &&
                    ! val.motivo_nao_retorno_do_vale_pallet
                ) {
                    setErrors({
                        motivo_nao_retorno_do_vale_pallet: 'Explique por que não vai ter o retorno'
                    })
                    return;
                }

                // emitiu vale pallet e não colocou a foto do vale
                if (
                    val.status_nota_pallet_gestao_entrega == 3 &&
                    ! val.url_imagem_vale_pallet
                ) {
                    setErrors({
                        url_imagem_vale_pallet: 'Adicione a foto do vale pallet'
                    })
                    return;
                }
            }

            const { latitude, longitude } = await buscaCoordenadasAtuais();

            console.log('registrar entrega', val);
            dispatch(entregaActions.registraNovoGestaoEntrega({
              gestao: {
                ...val,
                latitude,
                longitude,
              },
              carga: carga as Carga
            }))
          }}
        >
          {({ values, handleSubmit }) => (
            <Form>
              <InputField
                label="Nome do Recebedor"
                placeholder="Nome do Recebedor"
                name="nome_recebedor"
              />
              {/* <InputField
                label="Documento do Recebedor (RG ou CPF)"
                placeholder="Documento do Recebedor (RG ou CPF)"
                name="rg_recebedor"
                type="tel"
              /> */}

              <Images large={true} />

              <ModalObservacoes values={values} notaPallet={!e_transbordo && nota?.e_nota_pallet == '1'} onSubmited={handleSubmit} onOpenModal={openModalObservacoes} onOpenedModal={() => setOpenedModalObservacoes(true)} />

              <BotaoFinalizarEntrega />
            </Form>
          )}
        </Formik>}
      </Flex>
    </Layout>
  )
}

export default RegistrarEntrega;