import { Form, Formik } from "formik";
import { TransbordoItem } from "../types";
import { ButtonForm, InputField } from "../../../components";
import toasts from "../../../utils/toasts";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { Button, Input } from "@chakra-ui/react";
import { apiCall } from "../../../utils/apiCall";
import { formatError } from "../../../utils/formatError";
import { retornaApenasNumeros } from "../../../utils/formatNumber";
import { formatCelular } from "../../../utils/formatCelular";

type ConfigurarMotoristaNotasSelecionadasProps = {
    notasUkeysSelecionadas: string[];
    notasFiscais: TransbordoItem[];
    j14Ukey: string;
    buscarDados: () => void;
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;
}

type GerarLinkForm = {
    entrega_motorista_agrupamento: string;
    entrega_celular_motorista_agrupamento: string;
}

export const ConfigurarMotoristaNotasSelecionadas = ({ notasUkeysSelecionadas, notasFiscais, j14Ukey, buscarDados, isLoading, setIsLoading }: ConfigurarMotoristaNotasSelecionadasProps) => {
    const notasSelecionadas = (): TransbordoItem[] => {
        return notasFiscais.filter((nota) => notasUkeysSelecionadas.some((i) => i == nota.j10_ukey));
    }

    type GerarLinkResponse = {
        linkGerado: string;
        celularMotorista: string;
        nomeMotorista: string;
    }

    const [gerarLinkResponse, setGerarLinkResponse] = useState<GerarLinkResponse | null>(null)

    const gerarLink = async (form: GerarLinkForm) => {
        try {
            setIsLoading(true);
            const resp: AxiosResponse<GerarLinkResponse> = await apiCall({
                url: `/gestao-de-entrega/v2/transbordos/gerar-link-transbordo`,
                method: 'POST',
                data: {
                    j14_ukey: j14Ukey,
                    j10_ukeys: notasUkeysSelecionadas,
                    entrega_motorista_agrupamento: form.entrega_motorista_agrupamento,
                    entrega_celular_motorista_agrupamento: form.entrega_celular_motorista_agrupamento,
                }
            })

            setGerarLinkResponse(resp.data);
            setIsLoading(false);
            buscarDados();
        } catch (err: any) {
            setIsLoading(false);
            console.log(err.response.data);
            toasts.error(formatError(err.response.data));
        }
    }

    const enviarLinkAoMotorista = async () => {
        try {
            if (!gerarLinkResponse) {
                return;
            }
            setIsLoading(true);
            const resp: AxiosResponse<GerarLinkResponse> = await apiCall({
                url: `/gestao-de-entrega/v2/transbordos/enviar-link-transbordo`,
                method: 'POST',
                data: {
                    telefone: gerarLinkResponse.celularMotorista,
                    motorista: gerarLinkResponse.nomeMotorista,
                    link: gerarLinkResponse?.linkGerado,
                }
            })

            toasts.success('Dentro de minutos a mensagem chegará ao motorista com o link para preenchimento dessas notas.', 5000)

            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            console.log(err.response.data);
            toasts.error(formatError(err.response.data));
        }
    }

    if (!!gerarLinkResponse) {
        return <div
            className="w-full fixed bottom-0 px-2 border rounded-t-xl border-gray-400 md:h-48 bg-gray-200 flex flex-wrap max-w-[1200px]"
        >
            <div className="w-full flex flex-wrap">
                <div className="w-full">
                    <h1 className="w-full text-center uppercase py-2">
                        Link gerado com sucesso!
                    </h1>
                </div>
                <div className="w-full flex flex-col max-w-[800px] h-48 mx-auto">
                    <Input className="w-full mb-1" value={gerarLinkResponse.linkGerado} bg={'white'} />
                    {gerarLinkResponse.celularMotorista && <div className="flex mx-auto mb-1">
                        <Button colorScheme="blue" isLoading={isLoading} onClick={enviarLinkAoMotorista}>
                            Enviar link ao WhatsApp do motorista {formatCelular(gerarLinkResponse.celularMotorista)}
                        </Button>
                    </div>}
                    <div className="flex mx-auto">
                        <Button colorScheme="blackAlpha" onClick={() => setGerarLinkResponse(null)}>
                            Fechar esta tela
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    }

    if (notasUkeysSelecionadas.length === 0) {
        return <></>;
    }

    return (
        <div
            className="w-full fixed bottom-0 px-2 border rounded-t-xl border-gray-400 md:h-48 bg-gray-200 flex flex-wrap max-w-[1200px]"
        >
            <div className="w-full flex flex-wrap">
                <div className="w-full">
                    <h1 className="w-full text-center uppercase">
                        Gerar link para registrar entregas das notas fiscais selecionadas e enviar ao motorista
                    </h1>

                    <div className="text-xs">
                        {notasUkeysSelecionadas.length} de {notasFiscais.length} selecionadas
                    </div>
                </div>
                <div className="md:w-2/3 flex flex-wrap overflow-y-auto h-48 content-start">
                    <div className="grid grid-cols-3 w-full pb-12">
                        {notasSelecionadas().map((nota) => (
                            <div
                                key={nota.j10_ukey}
                                className="p-1 mr-1 mb-1 border text-xs bg-white md:min-h-11 rounded shadow"
                            >
                                <span className="font-semibold uppercase text-sm">Nota Fiscal {nota.nota_numero}</span> <br />
                                <span className="text-xs">{nota.cliente_cidade} - {nota.cliente_uf} ({nota.cliente_cep})</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`md:w-1/3 flex flex-wrap pl-2`}>
                    <Formik
                        initialValues={
                            {
                                entrega_motorista_agrupamento: '',
                                entrega_celular_motorista_agrupamento: "",
                            } as GerarLinkForm
                        }
                        onSubmit={(values, { setErrors }) => {
                            let errors = {};

                            if (!values.entrega_motorista_agrupamento) {
                                errors = {
                                    entrega_motorista_agrupamento: "Campo obrigatório",
                                };
                            }

                            const celularFormatado = retornaApenasNumeros(values?.entrega_celular_motorista_agrupamento ?? '');

                            if (celularFormatado.length > 0) {
                                if (celularFormatado.length < 10 || celularFormatado.length > 11) {
                                    errors = {
                                        entrega_celular_motorista_agrupamento: "Preencha corretamente com o DDD",
                                    };
                                }
                            }

                            if (Object.keys(errors).length > 0) {
                                setErrors(errors);
                                return;
                            }

                            console.log("form", values);
                            gerarLink({
                                ...values,
                                entrega_celular_motorista_agrupamento: celularFormatado,
                            });
                        }}
                    >
                        <Form className={`flex flex-col gap-3 w-full ${isLoading ? 'cursor-not-allowed	' : ''}`}>
                            <InputField
                                label=""
                                placeholder="Nome motorista"
                                name="entrega_motorista_agrupamento"
                            />
                            <InputField
                                label=""
                                placeholder="Celular motorista"
                                name="entrega_celular_motorista_agrupamento"
                                mask="(99)99999-9999"
                            />

                            <ButtonForm isLoading={isLoading} type="submit" colorScheme="green" className="w-full">
                                Gerar link
                            </ButtonForm>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}