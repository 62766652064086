export const buscaCoordenadasAtuais = async () => {
  return await new Promise<{
    latitude: number;
    longitude: number;
  }>((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        resolve({
          latitude,
          longitude,
        });
      },
      (error) => {
        resolve({
          latitude: 0,
          longitude: 0,
        });
      }
    );
  });
};
