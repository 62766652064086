import { Box, Center, Checkbox, Flex, FormLabel, Heading, Input, Select, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ButtonBack, Error, Layout } from "../../../components";
import { RootState } from "../../app/mainReducer";
import { CargaFinalizada } from "../../carga/components";
import { OpcoesDownloadCarga, TransbordoItem } from "../components";
import { transbordoActions } from "../reducer";
import { ConfigurarMotoristaNotasSelecionadas } from "../components/ConfigurarMotoristaNotasSelecionadas";
import { ordenarRegistros } from "../../../utils/ordenarRegistros";
import { TransbordoItem as TransbordoItemType } from "../types";

const TransbordoEdit = () => {
  const isLoading = useSelector((state: RootState) => state.transbordo.isLoading);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const transbordo = useSelector((state: RootState) => state.transbordo.transbordo);
  const error = useSelector((state: RootState) => state.transbordo.error || '');
  const networkOnline = useSelector((state: RootState) => state.login.networkOnline || false);

  const { j14_ukey } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!j14_ukey) return;

    if (networkOnline) {
      dispatch(transbordoActions.transbordoRequest({ j14_ukey }));
    } else {
      dispatch(transbordoActions.transbordoError('Não é possível buscar as entregas, você está sem internet.'));
    }
  }, [dispatch, transbordoActions, j14_ukey])

  const search = () => {
    if (networkOnline) {
      if (!j14_ukey) return;

      dispatch(transbordoActions.transbordoRequest({ j14_ukey }));
    }
  }
  const [filtro, setFiltro] = useState("");
  const [ordenacao, setOrdenacao] = useState<keyof TransbordoItemType>('nota_numero');

  const [itens, setItens] = useState<TransbordoItemType[]>([]);

  const atualizaRegistros = () => {
    setItens(ordenarRegistros<TransbordoItemType>((transbordo?.itens ?? []).filter((i) => {
      if (!filtro) {
        return true;
      }

      return i.cliente_fantasia.toLowerCase().includes(filtro.toLowerCase()) ||
        i.cliente_razao_social.toLowerCase().includes(filtro.toLowerCase()) ||
        i.nota_numero.toLowerCase().includes(filtro.toLowerCase())
    }), ordenacao));
  }

  useEffect(() => {
    atualizaRegistros();
  }, [transbordo?.itens])

  useEffect(() => {
    atualizaRegistros();
  }, [filtro, ordenacao])

  const [eMobile] = useMediaQuery('(max-width: 768px)')

  const [notasSelecionadas, setNotasSelecionadas] = useState<string[]>([]);

  const estaoTodasAsNotasSelecionadas = () => itens.every((i) => notasSelecionadas.some((j) => j == i.j10_ukey));

  return (
    <Layout maxWTop="600px" backTo="/transbordos" title={<ButtonBack to="/transbordos" />} maxW="1250px" isLoading={isLoading || isLoadingLocal} onSearch={search}>
      <>
        <Flex width="full" wrap="wrap">
          <Flex width={eMobile ? '100%' : '22%'} direction="column" mt={5} height="full" position="relative">
            {isLoading && <Skeleton rounded="md" height='380px' mb={1} />}
            {!isLoading && <OpcoesDownloadCarga j14_ukey={j14_ukey as string} j38_ukey={transbordo?.j38_ukey as string} />}
          </Flex>

          <Box width={eMobile ? '100%' : '78%'}>
            <Center mt={1} mb={2}>
              <Heading>Transbordo {transbordo?.numero}</Heading>
            </Center>
            <Center mt={1} mb={2}>
              <Heading size="md">{transbordo?.transbordo_nome}</Heading>
            </Center>
            <Center mt={1} mb={2}>
              <Heading size="sm" my={4}>
                Este é um painel de realização das entregas da carga {transbordo?.numero}.
              </Heading>
            </Center>

            <Error error={error} />

            {itens.length === 0 && !isLoading && !filtro &&
              <CargaFinalizada />
            }


            {(itens.length > 0 || (itens.length == 0 && filtro)) &&
              <Flex className="w-full flex flex-wrap">
                <Flex className="full flex-col md:w-3/4 px-2">
                  <FormLabel>Filtrar pelo número da Nota ou Cliente</FormLabel>
                  <Input mb={4} bg="white" placeholder="Digite o número da nota ou nome do cliente" value={filtro} onChange={(e) => setFiltro(e.target.value)} />
                </Flex>
                <Flex className="full flex-col md:w-1/4 px-2">
                  <FormLabel width={"100%"}>Ordenar por</FormLabel>
                  <Select width={"100%"} mb={4} bg="white" value={ordenacao} onChange={(e) => setOrdenacao(e.target.value as keyof TransbordoItemType)}>
                    <option value="nota_numero">
                      Nota fiscal
                    </option>
                    <option value="cliente_cidade">
                      Cidade
                    </option>
                  </Select>
                </Flex>
              </Flex>
            }
            {
              isLoading && [1, 2, 3, 4, 5, 6].map((i) => (
                <Skeleton mx={2} height='110px' mb={1} key={i} />
              ))
            }
            {!isLoading &&
              <Box bg={'white'} p={2} mx={2} mb={2}>
                <Checkbox size={"lg"} borderColor={"gray"} isChecked={estaoTodasAsNotasSelecionadas()} onChange={(evt) => setNotasSelecionadas(evt.target.checked ? itens.map(i => i.j10_ukey) : [])}> Selecionar todas </Checkbox>
              </Box>
            }
            {
              !isLoading && itens.map((transbordo_item) => (
                <TransbordoItem
                  setSelectedItem={(isSelected) => {
                    const notasSelecionadasLocal: string[] = JSON.parse(JSON.stringify(notasSelecionadas));

                    const itemIndex = notasSelecionadasLocal.findIndex((i) => i == transbordo_item.j10_ukey);

                    if (isSelected) {
                      if (itemIndex === -1) {
                        setNotasSelecionadas((old) => [...old, transbordo_item.j10_ukey])
                      }
                      return;
                    }
                    
                    if (itemIndex === -1) {
                      return;
                    }

                    notasSelecionadasLocal.splice(itemIndex, 1);

                    setNotasSelecionadas(notasSelecionadasLocal);
                  }}
                  isSelected={notasSelecionadas.some((i) => i == transbordo_item.j10_ukey)}
                  item={transbordo_item}
                  key={transbordo_item.j10_ukey}
                />
              ))
            }
          </Box>
        </Flex>

        <ConfigurarMotoristaNotasSelecionadas
            notasUkeysSelecionadas={notasSelecionadas}
            notasFiscais={transbordo?.itens ?? []}
            j14Ukey={j14_ukey as string}
            buscarDados={() => {
                setNotasSelecionadas([]);
                search();
            }}
            isLoading={isLoadingLocal}
            setIsLoading={setIsLoadingLocal}
        />
      </>
    </Layout>
  )
}

export default TransbordoEdit;