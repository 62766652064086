import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { entregaActions } from "../modules/entrega/reducer";
import { buscaCoordenadasAtuais } from "../utils/geolocalizacao";

export function useGeolocalizacaoAtual() {
  const dispatch = useDispatch();
  const { ukey } = useParams();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!ukey) return;

      const { latitude, longitude } = await buscaCoordenadasAtuais();

      dispatch(entregaActions.registraNovaLocalizacaoMotorista({
        ukey,
        latitude,
        longitude,
      }))
    }, 1000 * 30); // 30 segundos

    return () => clearInterval(interval);
  }, []);
}
