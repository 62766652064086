import { GestaoEntrega } from "../types";

const novoGestaoDeEntrega = (): GestaoEntrega => {
  return {
    a24_ukey: "",
    data_entrega: "",
    latitude: "",
    longitude: "",
    motivo_devolucao: "1",
    nome_recebedor: "",
    rg_recebedor: "",
    tempo_entrega: "1",
    url_imagem1: "",
    url_imagem2: "",
    validado_pelo_faturamento: "0",
    cadastrado_pelo_faturamento: "0",
    j81_ukeyp: "",
    j81_par: "",
    e_tentativa_entrega: 0,
    data_inicio: "",
    data_fim: "",
    observacoes: "",
    status_nota_pallet_gestao_entrega: 1,
    teve_devolucao_mercadoria: null as any,
    imagem1_rejeitada: 0,
    imagem2_rejeitada: 0,
    imagem1_recolocada: 0,
    imagem2_recolocada: 0,
    url_imagem_vale_pallet: '',
    motivo_nao_retorno_do_vale_pallet: '',
  }
}

export default novoGestaoDeEntrega;